
import { Vue, Component } from 'vue-property-decorator'
import http from '@/api/http'

@Component
export default class MedicineList extends Vue {
  private list: any[] = []
  private created () {
    http.get('/papi/drug/getByIds', {ids: '1,158,14,6,208,53'}).then((res) => {
      this.list = res.result.list
    })
  }
}
